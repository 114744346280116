<template>
  <div class="File-Apply">
    <div class="stu-module-header">
      <div class="stu-module-title">文件申请</div>
      <el-button
        @click="addApply"
        type="primary"
        icon="el-icon-plus"
        v-if="$sysPerKey('eduStudentMaterial:insertEduStudentMaterial')"
        >添加申请材料</el-button
      >
    </div>

    <div v-if="queryRoot">
      <el-table
        :data="tableData"
        :height="height"
        ref="applyRef"
        v-loading="loading"
        stripe
        class="table"
      >
        <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in columns">
          <el-table-column
            :key="index"
            v-if="item.prop === 'checkStatuss'"
            :label="item.label"
            :prop="item.prop"
            align="center"
          >
            <template slot-scope="scope">
              <span class="orange">{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :prop="item.prop"
            :key="index"
            :label="item.label"
            align="center"
            :show-overflow-tooltip="true"
            :formatter="item.formatter ? formatters : null"
          >
          </el-table-column>
        </template>
        <el-table-column
          label="操作"
          align="center"
          v-if="$sysPerKey('eduStudentMaterial:updateEduStudentMaterial')"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.checkStatus === 0"
              type="text"
              @click="edit(scope.row, scope.$index)"
            >
              修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <no-root v-else></no-root>
    <el-dialog :title="scope.title" :visible.sync="scope.dialog" width="30%">
      <el-form
        ref="applyDialogForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <el-form-item label="姓名" prop="studentName">
          <el-input
            v-model="formData.studentName"
            readonly
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="申请类型" prop="applyType">
          <el-select
            v-model="formData.applyType"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="(item, index) in applyTypeOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请说明" prop="applyDesc">
          <el-input
            v-model="formData.applyDesc"
            type="textarea"
            placeholder="请输入申请说明"
            :maxlength="100"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请附件">
          <el-upload
            class="upload-demo"
            :action="`${url}hnjxjy-core/eduStudentMaterial/MaterialUpload`"
            :headers="{
              'x-token': auth['x-token'],
              'x-perm': auth['x-perm'],
            }"
            ref="upload"
            :on-success="onSuccess"
            :before-upload="beforeUpload"
            :file-list="formData.file"
          >
            <el-button size="small" type="primary">选择附件</el-button>
            <p style="color: red;font-size: 14px; display: inline-block; margin-left: 10px; font-weight: bold;">仅支持office文件格式</p>
          </el-upload>
        </el-form-item>
        <el-form-item label="查看附件" v-if="scope.fileShow">
          <el-button type="text" @click="download_file">查看</el-button>
        </el-form-item>
        <el-form-item label="审核状态" prop="checkStatus">
          <el-input
            v-model="formData.checkStatuss"
            readonly
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          :loading="btnLoading"
          type="primary"
          @click="confirm('applyDialogForm')"
        >
          确认{{ scope.flag === 1 ? "修改" : "添加" }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryEduStuMat,
  QueryEduStuMatById,
  UpdateEduStudentMaterial,
  InsertEduStudentMaterial,
} from "@/libs/api/student";
import { BASE_URL } from "@/libs/domain";
export default {
  data() {
    return {
      height: window.innerHeight - 250,
      loading: false,
      btnLoading: false,
      scope: {
        dialog: false,
        title: null, // 模态框标题
        data: null,
        url: null,
        flag: 0, // 0 添加 1 修改
        fileShow: false,
      },
      tableData: [],
      columns: [
        { prop: "studentName", label: "姓名" },
        { prop: "applyType", label: "申请类型", formatter: true },
        { prop: "applyDesc", label: "申请说明" },
        { prop: "createTime", label: "申请时间" },
        { prop: "checkStatuss", label: "审核状态" },
        { prop: "loginAccount", label: "审核人姓名" },
        { prop: "checkTime", label: "审核时间" },
        { prop: "remark", label: "审核备注" },
      ],
      pageSize: 20,
      current: 1,
      total: 0,

      formData: {
        studentName: null,
        applyType: null,
        applyDesc: "",
        file: [],
        checkStatuss: "审核中",
      },
      rules: {
        applyType: [
          {
            required: true,
            message: "请选择申请类型",
            trigger: "blur",
          },
        ],
        applyDesc: [
          {
            required: true,
            message: "请输入申请说明",
            trigger: "blur",
          },
        ],
      },
      applyTypeOptions: [
        {
          label: "在线考试成绩",
          value: 0,
        },
        {
          label: "已毕业证明",
          value: 1,
        },
        {
          label: "退学申请",
          value: 2,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    url() {
      return BASE_URL;
    },
    queryRoot() {
      return this.$sysPerKey("eduStudentMaterial:queryEduStuMat");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.init();
    },
  },
  mounted() {
    this.queryRoot && this.init();
  },
  methods: {
    init() {
      this.loading = true;
      QueryEduStuMat({
        page: this.current,
        pageSize: this.pageSize,
        studentId: this.user.id,
      })
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addApply() {
      this.scope = {
        title: "新增文件申请资料",
        dialog: true,
        data: null,
        flag: 0,
        url: null,
        fileShow: false,
      };
      this.formData = {
        studentName: this.user.name,
        applyType: null,
        applyDesc: "",
        file: [],
        checkStatuss: "审核中",
      };
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },

    edit(row, index) {
      if (row) {
        this.scope = {
          title: "修改文件申请资料",
          dialog: true,
          data: row,
          flag: 1,
          url: row.attachmentUrl,
          fileShow: row.attachmentUrl ? true : false,
        };
        this.formData = row;
      }
    },
    confirm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        const { flag, data, url } = this.scope;
        if (flag === 0) {
          const { applyType, applyDesc, file } = this.formData;
          if (!file.length) {
            return this.$message.error("请选择附件");
          }
          this.btnLoading = true;
          InsertEduStudentMaterial({
            applyType,
            applyDesc,
            attachmentUrl: url,
          })
            .then((res) => {
              this.$message.success("材料添加成功");
              this.scope.dialog = false;
              this.formData.file = [];
              this.init();
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          this.btnLoading = true;
          UpdateEduStudentMaterial({
            id: data.id,
            applyType: data.applyType,
            applyDesc: data.applyDesc,
            attachmentUrl: url,
          })
            .then((res) => {
              this.$message.success(res.data);
              this.scope.dialog = false;
              this.formData.file = [];
              this.init();
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    cancel() {
      this.scope.dialog = false;
      // this.$refs["applyDialogForm"].resetFields();
    },

    formatters(row, column) {
      if (column.property == "applyType") {
        return this.applyTypeFM(this.applyTypeOptions, row.applyType);
      } else {
        // 返回其他不需要处理的正常参数
        return row[column.property];
      }
    },

    applyTypeFM(options, value) {
      return options.find((f) => f.value === value).label || "-";
    },

    onSuccess(res) {
      if (res.code === 2000) {
        this.scope.url = res.data.data;
        this.scope.fileShow = true;
        this.$message.success("上传成功");
      }
    },
    beforeUpload(file) {
      this.formData.file = [file];
    },

    download_file() {
      window.open(this.scope.url);
    },
  },
};
</script>

<style scoped lang="less">
.File-Apply {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .table {
    margin-top: 20px;
    .orange {
      color: #ff9b22;
      font-weight: bold;
    }
  }
}
</style>